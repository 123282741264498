import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl, Token, config, familyCode, selectStyles} from '../component/include'

import Select from 'react-select'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import cellEditFactory from 'react-bootstrap-table2-editor';

import { useSelector } from 'react-redux'
import { getTime, longDate } from '../component/globalFunction'
const ConferenceLog =()=> {
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    
    
const [studentList, setStudentList] = useState([]);
const [staffList, setStaffList] = useState([]);
    const staff  = useSelector(state => state.rootReducer.staff);
    const [conference, setConference] = useState({
        conference:[],
        staff:[],
        purpose:'',
        student:[],
        parentCode:familyCode,
        visitTime:''
    })
    const [allconference, setAllconference]= useState([])
    const [activeConference, setActiveConference]= useState([])
    const [timeInterval,  setTimeInterval]= useState([])

   
    const handleDelete = (ID)=>{  
        setNotice({...notice,  isLoading: true}) 
           let close =   document.getElementById('btnWarningDialog-'+ID)
              close.click();
           const fd = new FormData();
           fd.append('ID', 'ID')
            fd.append('data', ID)
            fd.append("jwt", Token);
            let url = ServerUrl+'/delete_controller/tbl_parent_conference';
           axios.post(url, fd, config)
           .then(response =>{
            if(response.data.type ==='success'){
               Alerts('Saved!', 'success', response.data.message)
                   } else{
               Alerts('Error!', 'danger', JSON.stringify(response.data))
                   }   
           })
           .catch((error)=>{
             Alerts('Error!', 'danger', error.message)
           }).finally(()=>{
              fetchconference()
               setNotice({...notice, 
                   isLoading: false
               })
           }) 
    }

    const fetchStudents =()=>{
        setNotice({...notice, isLoading: true}) 
        var sql ="SELECT admissionNumber, registerNumber, studentName, classID, sectionID, code, fatherName, father_mobile, motherEmail, fatherEmail from tbl_students where admissionStatus ='Studying' and  family_name ='"+familyCode+"'"
    
        const fd = new FormData();
        fd.append('jwt', Token);
    fd.append("sql", sql);
    let url = ServerUrl+'/fetchBySql_controller'
    axios.post(url, fd, config).then(result=>{
        
    setStudentList(result.data)
    })
    .catch((error)=>{
    Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice, isLoading: false})
        
    })
    }


    const fetchActiveConference =()=>{
        const fd = new FormData(); 
        var today = new Date().toISOString().slice(0,10);

        var sql ="Select code, conferenceName, conferenceDate, startTime, endTime, intervalMins from tbl_conference where status ='Open' and conferenceDate >= '"+today+"'"; 



        fd.append("sql", sql);
        fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config).then(result =>{
        setActiveConference(result.data)
    } 
    
    ) 
    }


    const fetchTeacher =(studentCode)=>{
        const fd = new FormData();  
        let menu = [];
        let newStaff = [];
        var sql ="SELECT teacher from tbl_exam_result where studentCode ='"+studentCode+"' group by teacher" ;

        fd.append("sql", sql);
        fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config).then(result =>{
        if(result.data.length !==0){
            
            let response  = result.data.map(item=>menu.push(item.teacher.slice(2,item.teacher.length-2)))
            
            staff.map(item=>menu.includes(item.code)?newStaff.push(item):[])
        }
    
        if(newStaff.length!==0){
            setStaffList(newStaff)
        }else{
            setStaffList(staff)
        }
    } 
    ) 
    }


    const tableHeader = [
       
        {dataField: 'conferenceName', text: 'Conference', editable: false, formatter:(cell)=><span style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span> },
        {dataField: 'visitTime', text: 'Time of Visit', editable: false, formatter:(cell, row)=>longDate(row.conferenceDate)+' ('+row.visitTime+') ' },
        {dataField: 'purpose', text: 'Purpose', editable: false, formatter:(cell)=><span style={{display:'block',  width:300, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span> },
        
        {dataField: 'staffName', text: 'To See', editable: false },
          {dataField: 'status', text: 'status', editable: false, formatter: (cell)=>cell==='Confirmed'? <span className="pcoded-badge label label-success">{cell}</span>: <span className="pcoded-badge label label-danger">{cell}</span>},
     ];

  
       
   

const fetchconference =()=>{
    setNotice({...notice,  isLoading: true})

    var sql ="Select c.ID, c.code, c.purpose, c.conferenceCode, c.staffCode, cf.conferenceDate, c.parentCode, c.visitTime, c.dateCreate, c.status, s.staffName, cf.conferenceName, f.familyName  from tbl_family f, tbl_conference_log c, tbl_staff s, tbl_conference cf where c.staffCode = s.code and cf.code = c.conferenceCode and f.code = c.parentCode order by c.ID DESC "; 

const fd = new FormData();
        fd.append('jwt', Token); 
fd.append("sql", sql);
let url = ServerUrl+'/fetchBySql_controller'
axios.post(url, fd, config).then(result=>{

if(result.data.length>0){
    setAllconference(result.data)
}
  

})
.catch((error)=>{
    Alerts('Error!', 'danger', error.message)
}).finally(()=>{
    setNotice({...notice, isLoading: false})
})
}




const BasicTable=(props)=>{

    const handleUpdate=(column, newValue, ID)=>{       
        const fd = new FormData(); 
        fd.append('newValue', newValue);
        fd.append('column', column.dataField);
        fd.append('ID', ID);
        fd.append("jwt", Token);
       let url = ServerUrl+'/updateCustom_controller/tbl_conference'
      axios.post(url, fd, config)
       .then()
       .catch((error)=>alert(error)) 
       
     } 
  
     const customTotal = (from, to, size) => (
        <span >&nbsp;Showing { from } to { to } of { size } entries</span>
      );
      
const { SearchBar } = Search;
     const options = {
         showTotal: false,
     paginationTotalRenderer: customTotal,
     sizePerPageList: [{text: '100', value: 100}, {text: '200', value: 200}, {text: '500', value: 500},
     { text: 'All', value: props.data.length }]
        
       };
  
     return <React.Fragment>  
    
             <ToolkitProvider search  className="table-responsive"
                 keyField='code' data={ props.data } columns={ props.columns } >
                     
                     {
                         props => (
                         <div >
                           
                             <SearchBar
                             { ...props.searchProps }  className="form-control" placeholder="Type to search!!!" />
                             

                             <div className="dt-buttons btn-group pull-right"> 
      
                             <button data-toggle="modal" type='button'  data-target='#addConference' className="btn btn-outline-success" > <i className="fa fa-plus"></i>Book A Conference</button>
</div> 


                             <BootstrapTable
                             { ...props.baseProps }
                                         striped
                                     hover
                                     condensed
                                     noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                 pagination={paginationFactory(options) }
                                 cellEdit={ cellEditFactory({
                                   mode: 'dbclick',
                                   blurToSave: true,
                                   autoSelectText: true,
                         beforeSaveCell: (oldValue, newValue, row, column) => {
                           if(oldValue !==newValue){
                           handleUpdate(column, newValue, row.ID);
                           
                          return props.fetchdata;
                         }
                         
                       }
                                 }) }
                             />
                         </div>
                         )
                     }
  </ToolkitProvider>
  </React.Fragment>
  }


  
function submit(event){
    event.preventDefault()
        let errors = {};   
        if(conference.staff.length===0){
            errors.staff ='Please select staff to meet';
        } 

        if(conference.student.length===0){
            errors.student ='Please select student';
        }
        if(!conference.purpose){
            errors.purpose ='Please enter purpose';
        } 
        if(conference.visitTime.length===0){
            errors.visitTime ='Please enter visit time';
        } 
    
        if(conference.conference.length==0){
            errors.conference ='Please select criteria';
        } 

        if(conference.staff.length!==0 && conference.conference.length!==0 && conference.visitTime.length!==0 ){

            let staffSelect = allconference.filter(item=>item.conferenceCode===conference.conference.value&&item.staffCode===conference.staff.value && item.visitTime == conference.visitTime.value)

            if(staffSelect.length!==0){
            errors.staff  ='The selected staff has been booked for the same conference at the same time';
            }
        } 

        if(conference.staff.length!==0 && conference.conference.length!==0 && conference.visitTime.length!==0 ){

            let staffSelect = allconference.filter(item=>item.conferenceCode===conference.conference.value&&item.parentCode===familyCode && item.visitTime == conference.visitTime.value)

            if(staffSelect.length!==0){
            errors.visitTime  ='You already book an appointment for the same time';
            }
        } 
    
    setErrors(errors)
    if(Object.keys(errors).length === 0){
        
                setNotice({...notice, isLoading: true})    
                
    let close =   document.getElementById('addConference')
    close.click();


                const fd = new FormData(); 
                fd.append("jwt", Token);
                    let formData = conference; 
                   for(let k in formData){
                       fd.append(k,formData[k]);
                   };   
                   fd.append('staffCode', conference.staff.value);
                   fd.append('conferenceCode', conference.conference.value);
                   fd.append('visitTimes', conference.visitTime.value);
                   fd.append('code', 'cf'+Math.random().toString(36).substr(2,9));
                 

                 let url = ServerUrl+'/save_controller/tbl_parent_conference'
                          axios.post(url, fd, config)
                    .then(response =>{
                        if (response.data.type ==='success'){
                            Alerts('Saved!', 'success', response.data.message)
                        } else{
                            Alerts('Error!', 'danger', JSON.stringify(response.data))
                    }
                            
                    })
                    .catch(error=>Alerts('Error!', 'danger', JSON.stringify(error.message)) )
        .finally(()=>{
            setNotice({...notice,  isLoading: false})
                fetchconference()
                setConference({
                    conference:[],
        staff:'',
        purpose:'',
        parentCode:familyCode,
        visitTime:''
                }) 
            })
    
    
                }
            }
  
            const handleSelectStudent = (option, action)=>{
                setConference({...conference, [action.name]: option});
                setErrors({...errors, [action.name]:''})
                fetchTeacher(option.value)
             } 


            const handleChange = (e)=>{
                const {name, value} = e.target;
                setConference({...conference, [name]: value});
                setErrors({...errors, [name]:''})
             }


            const handleSelect = (option, action)=>{
                setConference({...conference, [action.name]: option});
                setErrors({...errors, [action.name]:''})

                if(action.name==='conference'){
                    getTimeInterval(option.startTime, option.endTime, option.intervalMins)
                }
             }

             function timeAddMinutes(time, min) {
                var t = time.split(":"),      // convert to array [hh, mm, ss]
                    h = Number(t[0]),         // get hours
                    m = Number(t[1]);         // get minutes
                m+= min % 60;                 // increment minutes
                h+= Math.floor(min/60);       // increment hours
                if (m >= 60) { h++; m-=60 }   
                
                return (h+"").padStart(2,"0")  +":"  //create string padded with zeros for HH and MM
                       +(m+"").padStart(2,"0") +":"
                       +t[2];                        // original seconds unchanged
                }  


             const getTimeInterval =(startTime, endTime, minuteToAdd)=>{
                var futureDate = timeAddMinutes(startTime, minuteToAdd);
                let timeList =[];
                var maxSlot =288; //maximum slot per day in 10 minutes interval
                
                for(var i=0; i<=maxSlot; i++){
                    if(String(futureDate)<=endTime){
                        timeList.push({startTime:startTime, endTime:futureDate, label:getTime(startTime)+' - '+getTime(futureDate)})
                        startTime = futureDate;
                        futureDate = timeAddMinutes(startTime, minuteToAdd);
                    }
                }

                setTimeInterval(timeList)
            }
useEffect(()=>{  
    fetchconference()
    fetchActiveConference()
    fetchStudents()
},[]);

    
        return (  <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Conference Request">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-home"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Staff</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Conference Log</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="modal fade " id='addConference' tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content ">
              <div className="modal-header">
                  <h6 className="modal-title"><i className='fa fa-info'></i> Book Conference Appointment</h6>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
  <span aria-hidden="true">&times;</span>
  </button>
              </div>
              <div className="modal-body">

			  <div className="row">
             


        <section className="col-md-6">
    <div className="form-group">
    <label >Select Conference </label>
    <Select   options={activeConference&&activeConference.map((data, idx)=> {
                                    return {key:idx, 
                                        value: data.code, 
                                        label: data.conferenceName, 
                                        conferenceDate: data.conferenceDate, 
                                        startTime:data.startTime, 
                                        endTime:data.endTime, 
                                        intervalMins:data.intervalMins  };
                                  })}

                                  getOptionLabel={option =>` ${option.label} (${longDate(option.conferenceDate)} ${getTime(option.startTime)} - ${getTime(option.endTime)}) `}
onChange={handleSelect} className={errors.conference ? 'form-control form-control-danger' : ''}  name="conference" value={conference.conference} styles={selectStyles} />
<span style={{color:'red'}}>{errors.conference && errors.conference}</span>
                </div>
        </section>


        <section className="col-md-6">
									
                                    <div className="form-group">
                                                <label>Select Student <span style={{color:'red'}}>*</span></label>
                                                <Select  options={
                                                    studentList.map((list, idx)=> {
                                                       return {key:idx, value: list.code, fatherName:list.fatherName, mobile:list.father_mobile, label: list.admissionNumber,  studentName:list.studentName}
                                                     })} 
                                                getOptionLabel={option =>option.label +' '+ option.studentName}
                        
                        onChange={handleSelectStudent} className={errors.student ? 'form-control form-control-danger' : ''}   name="student" value={conference.student}  /> 
                        
<span style={{color:'red'}}>{errors.student && errors.student}</span>

                                                                    </div>
                                                                </section>


        <section className="col-md-6">
   <div className="form-group">
<label >Select Visit Time <span style={{color:'red'}}>*</span></label>
      <Select  options={
                            timeInterval&&timeInterval.map((list, idx)=> {
                               return {key:idx, value: list.label, label:list.label}
                             })} 
onChange={handleSelect} className={errors.visitTime ? 'form-control form-control-danger' : ''} name="visitTime" value={conference.visitTime}  /> 

<span style={{color:'red'}}>{errors.visitTime && errors.visitTime}</span> 
   </div>

</section>


        <section className="col-md-6">
   <div className="form-group">
<label >Select Staff To Meet <span style={{color:'red'}}>*</span></label>
      <Select  options={
                            staffList&&staffList.map((list, idx)=> {
                               return {key:idx, value: list.code, label:list.staffName}
                             })} 
onChange={handleSelect} className={errors.staff ? 'form-control form-control-danger' : ''} name="staff" value={conference.staff}  /> <span style={{color:'red'}}>{errors.staff && errors.staff}</span>
   </div>

</section>




        <section className="col-md-12">
    <div className="form-group">
    
    <label >Purpose of Visit </label>
   <textarea type='text' rows={3} className={errors.purpose ? 'form-control form-control-danger' : 'form-control'} name="purpose" value={conference.purpose} onChange={handleChange} > {conference.purpose}</textarea>  
   <span style={{color:'red'}}>{errors.purpose&& errors.purpose}</span> 
                </div>



        </section>


				</div>
				
              </div>
              <div className="modal-footer">
                  <button type="button"  id='addConference' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
                  <button type="button" onClick={submit} className="btn btn-inverse">Book</button>
              </div>
          </div>
      </div>
  </div>


                <div className="row  ">
	
	<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5> <i className="fa fa-th" ></i> Conference Staff</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">


		<div className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
   <BasicTable data={allconference.filter(item=>item.parentCode===familyCode)}   columns={tableHeader}  />} 
</div>
	</div>
    </div>
</div>

</div>
	
   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(ConferenceLog) 