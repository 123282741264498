import React, {useState, useEffect} from 'react'
import { Token, config, ServerUrl, ImagesUrl } from './include';
import { Alerts } from './notify';
import axios from 'axios'
import Cookies from 'js-cookie'
import { decrypt } from './authentication';
import { compare, longDate } from './globalFunction';

import { useDispatch, useSelector } from 'react-redux';

const Header = ()=>{

    const dispatch = useDispatch()
    const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
    const [isFull, setIsFull] = useState(false)
/* Get the element you want displayed in fullscreen */ 
var elem = document.documentElement;

const IsFullMode =()=>{
     const windowWidth = window.innerWidth * window.devicePixelRatio;
    const windowHeight = window.innerHeight * window.devicePixelRatio;
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    if (((windowWidth/screenWidth)>=0.95) && ((windowHeight/screenHeight)>=0.95)) {
        closeFullscreen()
        return true;
    }
    else {
        openFullscreen()
        return false
    } 
}


/* Function to open fullscreen mode */
function openFullscreen() {
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) { /* Firefox */
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) { /* IE/Edge */
    elem = window.top.document.body; //To break out of frame in IE
    elem.msRequestFullscreen();
  }
  setIsFull(true);
}

/* Function to close fullscreen mode */
function closeFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    window.top.document.msExitFullscreen();
  }
  setIsFull(false);
}

//#404E67

const [users, setUsers] = useState([])
const [noticeboard, setNoticeboard]=useState([])
const [currentSession, setCurrentSession] = useState('')

const fetchNoticeBoard =()=>{
    var today = new Date().toISOString().slice(0,10);
    var sql ="Select  imageUrl, description, dateCreate, ID, createdBy from tbl_noticeboard where status ='Active' and receiver = 'Only Student' or receiver = 'Everyone' and  expiry >= '"+today+"'"
    const fd = new FormData();
    fd.append("sql", sql);
    fd.append("jwt", Token);
    let url = ServerUrl+'/fetchBySql_controller';
    
    axios.post(url, fd, config).then(result=>{ 
        if(result.data.length!==0){
        setNoticeboard(result.data)
        }
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    })

}


const  handleFetchData =()=>{

    var sql ="Select  startYear from tbl_school_session where status ='Active'"
    const fd = new FormData();
    fd.append("sql", sql);
    fd.append("jwt", Token);
    let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config)
    .then(result => {
        if(result.data.length!==0){
        setCurrentSession(result.data[0].startYear)
    }
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', JSON.stringify(error.message)) 
    })
    }

const fetchContent =()=>{
    if(Cookies.get('pkauscd')){
        const userCode =Cookies.get('pkauscd')
        setNotice({...notice, userCode:userCode})
    var sql ="Select familyName from tbl_family where code  ='"+userCode+"'";
     
    const fd = new FormData();
    fd.append("sql", sql);
    fd.append('jwt', Token);
    let url = ServerUrl+'/fetchBySql_controller'
    
    axios.post(url, fd, config).then(result=>{ 
        setUsers(result.data[0])
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        window.location.href='/'
    })
    
    }else{
        window.location.href='/'
    }
}

const fetchSidebar = async()=>{
    var sql ="Select  parent from tbl_navigation"
    const fd = new FormData();
    fd.append("sql", sql);
    fd.append("jwt", Token);
    let url = ServerUrl+'/fetchBySql_controller';
    
   await axios.post(url, fd, config).then(result=>{ 
        if(result.data.length!==0){

            let response = JSON.parse(result.data[0].parent).sort(compare)

             dispatch({
        type:'SET_RECORDS',
        name:'sidebar',
        data:response
    })
        }
    })

}

const lockScreen =()=>{
    if (localStorage.getItem("plcksr") === null) {
        localStorage.setItem('plcksr', 'islk');
      }
      document.getElementById('openlockscreenModal').click()

}


const fetchSchoolInfo = async ()=>{

    const fd = new FormData();
    fd.append('schoolDetails', 'schoolDetails')
    let url = ServerUrl+'/fetch_details'
        const {data} = await axios.post(url, fd, config)
        dispatch({
            type:'SET_RECORDS',
            name:'schoolInfo',
            data:data
        })
}

useEffect(()=>{
    fetchContent() 
    fetchNoticeBoard()
    handleFetchData()
    fetchSidebar()
  
fetchSchoolInfo()
},[]);

const schoolInfo  = useSelector(state => state.rootReducer.schoolInfo);
    return <>        
<nav className="navbar header-navbar pcoded-header no-print" header-theme="theme1" style={{backgroundColor:'#404E67', color:'#ffffff'}}>
    <div className="navbar-wrapper no-print">

        <div className="navbar-logo ">
            <a className="mobile-menu" id="mobile-collapse" href="#!">
                <i className="feather icon-menu"></i></a>
                <a href="/dashboard">
              {schoolInfo.length!==0? <img className="img-fluid" style={{width:'180px'}} src={ImagesUrl+'/about/'+schoolInfo[0].linelogo} alt="Logo" />:[]}
            </a>
            <a href="#!" className="mobile-options">
                <i className="feather icon-more-horizontal"></i>
            </a>
        </div>

        <div className="navbar-container container-fluid ">
            <ul className="nav-left" >
               

                <li>
                <a href="#!" style={{color:'white'}} onClick={!isFull?()=>openFullscreen():()=>IsFullMode()} >
                        <i className={`feather ${!isFull?'icon-maximize':'icon-minimize'} full-screen`} ></i>
                    </a> 
                </li>
                <li ><span style={{color:'#ffffff'}}><i className="feather icon-briefcase"></i> Current Session Is {currentSession}</span>
                </li>
            </ul>

            <ul className="nav-right no-print">
                
            {noticeboard.length!==0?  <li className="header-notification">
                    <div className="dropdown-primary dropdown">
                        <div className="dropdown-toggle" data-toggle="dropdown">
                            <i className="feather icon-bell"></i>
                            <span className="badge bg-c-pink">{noticeboard.length}</span>
                        </div>
                        <ul className="show-notification notification-view dropdown-menu" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">
                            <li>
                                <h6>Noticeboard</h6>
                                <label className="label label-danger">New</label>
                            </li>
                            {noticeboard.length!==0 ? noticeboard.slice(0,4).map((data, id)=>
                            <li key={id} onClick={()=>window.open('/noticeboard', '_self')}>
                                <div className="media">
                                <img className="d-flex align-self-center img-radius" src={ImagesUrl+'/noticeboard/'+data.imageUrl} alt="sender " onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+"/no.jpg"}} />
                                    <div className="media-body">
                                    <h5 className="notification-user">{data.createdBy}</h5>
                            <p className="notification-msg" dangerouslySetInnerHTML={{__html:data.description.slice(0,40)+'...'}} ></p>
                                        <span className="notification-time">{longDate(data.dateCreate)}</span>
                                    </div>
                                </div>
                            </li>
                            ):''}
                        
                        </ul>
                    </div>
                </li>:''}
              
                <li className="user-profile header-notification">
                    <div className="dropdown-primary dropdown">
                        <div className="dropdown-toggle" data-toggle="dropdown">
                            
                            <span>{users.familyName}</span>
                            <i className="feather icon-chevron-down"></i>
                        </div>
                        <ul className="show-notification profile-notification dropdown-menu" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">
                        <li><a href="/change_password"><i className="feather icon-settings"></i> Change Password</a></li>
                          
                          
                            <li>
    
                            <a href="#!" onClick={lockScreen}>
                        <i className="feather icon-lock"></i> Lock Screen
                    </a>
                            </li>
                            <li>
                                <a href="/">
                                    <i className="feather icon-log-out"></i> Logout
                                </a>
                            </li>
                        </ul>

                    </div>
                </li>
            </ul>
        </div>
    </div>
</nav>

    </>
}
export default React.memo(Header)