import React, {useState, useEffect, useRef} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import {Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ImagesUrl, ServerUrl,  Token,  config, schoolName, familyCode} from '../component/include'
import Select from 'react-select'
import { formatGroupLabel, getAge, getResultHead, longDate, FormatNumber} from '../component/globalFunction'
import { userToken } from '../component/authentication'

import { useSelector } from 'react-redux'
const ViewResult =()=> {
    const [errors, setErrors] = useState({});
    const structure = useRef(null)
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    const [examtype, setExamtype]=useState([])
    
const [studentClass, setStudentClass]=useState([])
    const [mystudent, setMystudent]=useState([])
    const [classTeacher, setClassTeacher] = useState([]);
    const [settings, setSettings]=useState([])
const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
const schoolInfo  = useSelector(state => state.rootReducer.schoolInfo);

const staff  = useSelector(state => state.rootReducer.staff);
 const [student, setStudent] = useState([]);
 const [studentList, setStudentList] = useState([]);
const [sqlQuery, setSqlQuery] = useState([]);  

const [studentAssessment, setStudentAssessment]=useState([])
const [assessment, setAssessment]=useState([])
const [house, setHouse]=useState([])
const [grade, setGrade] = useState([])
const [grademark, setGrademark]=useState([])
    const [scores, setScores]=useState([])
    const [onlyCategory, setOnlyCategory]=useState([])
    const [isSubmitting, setIsSubmitting] = useState(false);

    
    const [result, setResult] = useState({
        studentClass:[],
        examtype:[],
        student:[],
        code:'',
        teacherRemark:'',
        teacherName:'',
        headRemark:'',
        headName:'',
        headofSchool:'',
        resultHead:'Secondary'
    })

    const [signature, setSignature] = useState({});
    const [category, setCategory]=useState([])
    const [attendance, setAttendance]=useState({
        P:0,
        A:0
    })
    const [atResult, setAtResult]=useState({
        attendanceStart:'',
        attendanceEnd:'',
        nextTermBegin:''
    })

    

    const handleSearch = event =>{
        event.preventDefault();    
        setErrors(ValidateErrors(result));
        setIsSubmitting(true);
    }  
    const ValidateErrors=(values)=>{
        let errors = {};   
        if(values.studentClass.length===0){
            errors.studentClass ='Please select class';
        }   
        if(values.examtype.length===0){
            errors.examtype ='Please select subject';
        } 
        if(values.student.length===0){
            errors.student ='Please select student';
        } 
        return errors;
        }
        
        const fetchSettings =()=>{
            const fd = new FormData();
        fd.append('jwt', Token);
            let url = ServerUrl + '/fetchField/tbl_result_slip/settings/ID/1';
            axios.post(url, fd, config).then(result=>{
                setSettings(result.data[0])
              
            })
        }
        const fetchExamType =()=>{
            var sql ="SELECT CONCAT(t.session, ' ', t.term) as exam, r.examCode  from tbl_exam_result r, tbl_exam_type t where r.examCode = t.code and t.publish='Yes' and t.term = 'MOCK EXAM'  group by r.examCode order by exam ASC" ;
    
    const fd = new FormData();
    fd.append("sql", sql);
    fd.append('jwt', Token);
    let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config).then(result=>{
        setExamtype(result.data)
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    })
    }

    const filterStudentClass =()=>{
        let allClass = [...schoolClass]
        let fstudent =[...mystudent]
        let newClass = []
        
        for (var i in allClass){
        for(var k in fstudent){
            if(allClass[i].ID===fstudent[k].classID){
                newClass.push(allClass[i])
            }    
        }
        }
        
        const fclass =	newClass.map(e=>e['ID'])
        .map((e,i,final)=>final.indexOf(e)===i&&i)
        .filter(e=>newClass[e])
        .map(e=>newClass[e])
        
        setStudentClass(fclass)
            }

    const fetchMyStudentClass =()=>{
        var sql ="Select ID, code, admissionNumber, classID, sectionID  from tbl_students where family_name ='"+familyCode+"' order by sectionID ASC"; 
    
    const fd = new FormData();
    fd.append("sql", sql);
    fd.append('jwt', Token);
    let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config).then(result=>{
        setMystudent(result.data)
    })
    }
    
    const fetchRemark =()=>{
        var sql ="SELECT code, teacherRemark, headofSchool, teacherName, headRemark, teacherName, headName, resultHead from tbl_result_remarks where studentCode = '"+result.student.value+"' and examType = '"+result.examtype.value+"' and sectionID = '"+result.studentClass.value+"'" ;

const fd = new FormData();
fd.append("sql", sql);
fd.append('jwt', Token);
let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(response=>{
    if(response.data.length!==0){
        setResult({...result, 
            code:response.data[0].code, 
            teacherRemark:response.data[0].teacherRemark, 
            headRemark:response.data[0].headRemark, 
            headName:response.data[0].headName, 
            resultHead:response.data[0].resultHead,
            headofSchool:response.data[0].headofSchool,
            teacherName:response.data[0].teacherName })
    }else{
        setResult({...result, code:'rmk'+Math.random().toString(36).substr(2,9), 
        
        teacherRemark:'',
        teacherName:'',
        headRemark:'',
        headName:'',
        headofSchool:'',
        resultHead:'Secondary' })
    }
    
})
.catch((error)=>{
    Alerts('Error!', 'danger', error.message)
})
}

    const handleSelect =(option, action)=>{     
          
        setResult({...result, [action.name]:option});
        setScores('')
        setStudentAssessment('')
        setErrors({...errors, [action.name]:''})
        if(action.name==='studentClass'){
            fetchStudentList(option.value, result.examtype.value)
            fetchClassSubject(option.value, result.examtype.value)
            fetchClassTeaher(option.classID, option.value)
            fetchGradeMark(option.classtype)
        }else if(action.name==='examtype'){
            fetchStudentList(result.studentClass.value, option.value)
            fetchClassSubject(result.studentClass.value, option.value)
            fetchExamAttendance(option.value)
            filterStudentClass()
        }
    }


    const handleSelectStudent =(option, action)=>{     
        setResult({...result, [action.name]:option});
        setErrors({...errors, [action.name]:''})
        fetchStatistics(option.value)
        setScores('')
        setStudentAssessment('')
    }


    const fetchStatistics =(code)=>{
        setNotice({...notice, isLoading: true}) 

     const fd = new FormData();
    fd.append("sql", sqlQuery);
    fd.append('jwt', Token);
    let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config).then(result=>{
         if(result.data.length!==0){
    setStudent(result.data.filter(item=>item.code===code)[0])
        }  
    })
    .catch((error)=>{
    Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice, isLoading: false})
        
    }) 
    }

    

        const fetchStudentList =(section, exam)=>{
            setNotice({...notice, isLoading: true}) 
            var sql ="SELECT s.passport, s.code, s.gender, s.admissionNumber, s.house, s.dateOfBirth, s.registerNumber, s.studentName, s.family_name, e.examCode from tbl_students s, tbl_exam_result e where s.code = e.studentCode and e.examCode ='"+exam+"' and s.termination <> 'Yes' and  e.sectionID ='"+section+"' group by s.code"
        
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token);
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config).then(result=>{
            if(result.data.length!==0){

                setStudentList(result.data)
            }else{
                
        setStudentList([])
            }
        })
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isLoading: false})
           
        })
        }



        const fetchClassSubject =(sectionID, exam)=>{

            var sql ="SELECT s.subjectName, s.abbreviation, s.code, r.examCode from tbl_exam_result r, tbl_subject s where r.subjectCode = s.code and r.examCode ='"+exam+"' and r.sectionID ='"+sectionID+"' group by r.subjectCode  order by r.displayOrder ASC" ;
    
            const fd = new FormData();
            fd.append("sql", sql);
            fd.append('jwt', Token);
            let url = ServerUrl+'/fetchBySql_controller';
            axios.post(url, fd, config).then(response=>{
                if(response.data.length!==0){
                var subj = response.data;
    
                let sqlString = subj.map(data=>"sum(case when e.subjectCode = '"+data.code+"'  then e.total else 0 end) AS '"+data.subjectName+"'")
                   
             let final =   'SELECT  rank() over (order by sum(e.total) desc) position, cast(sum(e.total)/count(*) as decimal(10,2)) as average, s.code, s.passport, s.gender, s.house, s.dateOfBirth, s.admissionNumber, s.registerNumber, s.studentName, sum(e.total) as obtained, sum(e.maxMark) as obtainable, e.maxMark, e.absent, '+String(sqlString)+ ' from tbl_students s, tbl_exam_result e where s.code = e.studentCode and  e.examCode ="'+exam+'" and e.sectionID ="'+sectionID+'" group by e.studentCode '
    
            setSqlQuery(final)
               
            }else{
                setSqlQuery([]) 
            }
            })

        }

    
        const fetchAssessment =()=>{
            var sql ="SELECT category, assessment, assesmentGrade from tbl_standards_setting where ID="+1;
    const fd = new FormData();
    fd.append("sql", sql);
    fd.append('jwt', Token)
    let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config).then(result=>{
        if(result.data.length !==0){
        var categor = result.data[0].category
        var assessmen = result.data[0].assessment
    
       setCategory(JSON.parse(categor))
       setAssessment(JSON.parse(assessmen).sort((a, b) =>(a.categoryCode > b.categoryCode) ? 1 : -1))
      
        }
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    })
    }

       
        const getAssessment = (code, field) =>{        
       
            const reply = assessment&&assessment.filter(list=>list.code===code);
            const answer = reply.length!==0?reply[0][field]:'';
            return  String(answer)
            }

          

            const getCategory=(code)=>{
                const result = category && category.filter(list=>list.code===code);
              const answer = result.map((c)=>c.categoryName);
              return  String(answer)
            }

        const fetchStudentAssessment =()=>{
           // setNotice({...notice, isLoading: true}) 
            var sql ="SELECT  assessmentCode, grade from tbl_assessment_result where examCode ='"+result.examtype.value+"' and sectionID ='"+result.studentClass.value+"' and studentCode ='"+result.student.value+"'" ;


         const fd = new FormData();
        fd.append("sql", sql);
            fd.append('jwt', Token);
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config).then(response=>{
             if(response.data.length!==0){

                var assess = response.data;
    var list = [];
    for (var i = 0; i< assess.length;  i++) {
        list.push(
            {  code:assess[i].assessmentCode,
                assessmentName:getAssessment(assess[i].assessmentCode, 'assesmentName'),
                type:getCategory(getAssessment(assess[i].assessmentCode, 'categoryCode')),
                grade:assess[i].grade
            });
            }

            const onlyCategory =	list.map(e=>e['type'])
                        .map((e,i,final)=>final.indexOf(e)===i&&i)
                        .filter(e=>list[e])
                        .map(e=>list[e])

            setOnlyCategory(onlyCategory)
        setStudentAssessment(list)
        
            }else{
                setOnlyCategory([])
                setStudentAssessment([])  
            } 
        })
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        })
        }

        const fetchResult =()=>{
            setNotice({...notice, isLoading: true}) 
            fetchStudentAssessment() 
            var sql ="SELECT s.subjectName, s.abbreviation, e.firstCA, e.absent, e.position, e.secondCA, e.exam, e.total, e.grade, e.remark, e.comment, e.teacher, e.totalAverage  from tbl_exam_result e, tbl_subject s where e.subjectCode = s.code and e.examCode ='"+result.examtype.value+"' and e.sectionID ='"+result.studentClass.value+"' and e.studentCode ='"+result.student.value+"'   order by e.displayOrder ASC" ;


         const fd = new FormData();
        fd.append("sql", sql);
            fd.append('jwt', Token);
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config).then(result=>{
             if(result.data.length!==0){
        setScores(result.data)
        window.scrollTo({top:document.body.scrollHeight, left:0, behavior:'smooth'})
            }else{
                setScores('')
                Alerts('Info!', 'info', 'There are no schedule found for the selected exam')
            }  
        })
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isLoading: false})
            
        }) 
        }

        const fetchGrade =()=>{
            const fd = new FormData();
            fd.append('jwt', Token);
            let url = ServerUrl + '/fetchField/tbl_exam_settings/grade/ID/1';
            axios.post(url, fd, config)
            .then(result =>setGrade(result.data))
        }
        
        const fetchGradeMark =(classType)=>{
            const fd = new FormData();
            fd.append('jwt', Token);
            let url = ServerUrl + '/fetchField/tbl_exam_settings/gradeMark/ID/1';
            axios.post(url, fd, config)
            .then(result =>{

                if(result.data.length>0){
                    var grade = result.data.filter(item=>item.classtype===classType)
                    setGrademark(grade.sort((a, b) =>(Number(b.maximum) > Number(a.maximum)) ? 1 : -1))
                }else{
                    setGrademark([])
                }
            })             
        }


        const getGradeScore =(code)=>{
            const result = grade&&grade.filter(item=>item.code===code)
            return result.length!==0?result[0].gradeName:''
            }
        


            const fetchExamAttendance =(value)=>{
                let sql ='select startDate, endDate, nextTermBegin from tbl_result_attendance where exam = "'+value+'"'
        
                const fd = new FormData();
                fd.append('jwt', Token); 
                fd.append("sql", sql);
                let url = ServerUrl+'/fetchBySql_controller'
                axios.post(url, fd, config).then(response=>{
                    if(Array.isArray(response.data)&& response.data.length!==0){
                        setAtResult({...atResult, attendanceStart:response.data[0].startDate, 
                            attendanceEnd:response.data[0].endDate,
                        nextTermBegin:response.data[0].nextTermBegin  })
                       
                    }else{
                        setAtResult({...atResult, attendanceStart:'', attendanceEnd:'', nextTermBegin:''  })
                    } 
                })
                .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
        
            })
        }

            const getAttendance =()=>{

                let final =   'SELECT s.code, s.admissionNumber, s.studentName, sum(case when a.indicator  = "P" then 1 else 0 end) as P, sum(case when a.indicator  = "A" then 1 else 0 end) as A  from tbl_students s, tbl_student_attendance a where   s.code = a.studentCode and a.attendanceDate >= "'+atResult.attendanceStart+'" and a.attendanceDate <= "'+atResult.attendanceEnd+'" and a.studentCode = "'+result.student.value+'" and attendanceType ="fullday" group by a.studentCode '
        
                const fd = new FormData();
                fd.append('jwt', Token); 
                fd.append("sql", final);
                let url = ServerUrl+'/fetchBySql_controller'
                axios.post(url, fd, config).then(response=>{
                    if(Array.isArray(response.data)&& response.data.length!==0){
                        setAttendance(response.data[0])
                    }else{
                        setAttendance([])
                    } 
                })
                .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
        
            })
        }


        const fetchHouse =()=>{
            const fd = new FormData();
            fd.append("jwt", Token);
            let url = ServerUrl + '/fetchField/tbl_student_master/house/ID/1';
            axios.post(url, fd, config)
            .then(result =>setHouse(result.data))
        }

        const getHouseName = (code) =>{      
            
            
            const response = house&&house.filter(list=>list.code === code);
            return  response.length? String(response[0].houseName):'';
            }


            const handleChange =(e)=>{
                const {name, value }= e.target
                setResult({...result, [name]:value})
            }
        
         

            const submitRemarks =()=>{
                
var headName =getResultHead(result.studentClass.classtype)==='Secondary'?
settings.headofSecondary: getResultHead(result.studentClass.classtype)==='Primary'?
settings.headofPrimary: settings.headofNursery

                var teacher = classTeacher;
                var list = [];
                for (var i = 0; i< teacher.length;  i++) {
                    list.push(teacher[i].code);
                        }
        
                const fd = new FormData();
                    fd.append('jwt', Token);
                fd.append('classID', result.studentClass.classID);
                fd.append('sectionID', result.studentClass.value);
                fd.append('examType', result.examtype.value);
                fd.append('studentCode',  result.student.value);
                fd.append('headofSchool', settings.headofSchool);

                fd.append('headName', headName);
                fd.append('teacherRemark', result.teacherRemark);
                fd.append('headRemark', result.headRemark);
                fd.append('code', result.code);
                fd.append('teacherName', JSON.stringify(list, null, 2));
        
        
                fd.append('resultHead', getResultHead(result.studentClass.classtype));
                
        
        
            setNotice({...notice,  isLoading: true}) 
                    let url = ServerUrl+'/save_controller/tbl_result_remarks'
                axios.post(url, fd, config)
                .then(response =>{
                    if (response.data.type ==='success'){
                        Alerts('Saved!', 'success', response.data.message)
                    } else  if (response.data.type ==='info'){
                        Alerts('Update!', 'default', response.data.message)
                    } else{
                        Alerts('Error!', 'danger', JSON.stringify(response.data))
                }  
                })
                .catch((error)=>{
                    Alerts('Error!', 'danger', JSON.stringify(error.message)) 
                })
                .finally(()=>{
                setNotice({...notice, isLoading: false})
                })  
                
            }

            const fetchClassTeaher =(classID, sectionID)=>{
                var newStaff = staff&&staff.filter((item=>item.classID===classID&&item.sectionID===sectionID))
                setClassTeacher(newStaff)
            }

        useEffect(()=>{
            if(Object.keys(errors).length === 0 && isSubmitting){
            fetchResult()
            fetchRemark()
            getAttendance()
            }
            },[errors])



     const handleStaffName=(cell)=>{
        if(cell!=='[]' && cell!=''){
        return JSON.parse(cell).map((itm, idx)=>{
            return <span  key={idx}> {' '+getStaffField(itm, 'staffName')}</span>
        } );
    }else{
        return ''
    } 
    }




       const getStaffField = (code, field) =>{   
    
        const result = staff&&staff.filter(list=>list.code===code);
        const answer = result.map((c)=>c[field] ); 
        return  String(answer);
       }



useEffect(()=>{
    fetchHouse()
    fetchSettings()
    fetchGrade()
    fetchExamType()
    fetchAssessment()
    fetchMyStudentClass() 
},[]);



        return ( <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
               
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Mock Result">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-file-text"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Exams</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Mock Result</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row no-print ">
							

	<div className="col-sm-12">

    <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Student Mock Result</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div><div className="card-block">
        <div className="card z-depth-0">
		<div className="card-block">
		
        <div className="row">

                    
     <section className="col-md-4">
     <div className="form-group">
          <label> Exam Type <span style={{color:'red'}}>*</span></label>
          <Select  options={examtype&&examtype.map((list, idx)=> {
                               return {key:idx, value: list.examCode, label: list.exam, term: list.term }
                             })
                        } 
                       
onChange={handleSelect} className={errors.examtype ? 'form-control form-control-danger' : ''} name="examtype" value={result.examtype}  /> <span style={{color:'red'}}>{errors.examtype && errors.examtype}</span>

 
             </div>
     
     </section>
     <section className="col-md-4">
                    <div className="form-group">
         <label> Student Class<span style={{color:'red'}}>*</span></label>
         <Select  options={
                         studentClass&&studentClass.map((list, idx)=> {
                            return {key:idx, classtype:list.arms, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                return sec.sname !==''? {key:id, classID:list.ID, classtype:list.arms, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classtype:list.arms, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                            }) }
                          })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect}  className={errors.studentClass ? 'form-control form-control-danger' : ''} name="studentClass" value={result.studentClass} id='sessionId' /> 
<span style={{color:'red'}}>{errors.studentClass && errors.studentClass}</span>
            </div>
     
     </section> 
     <section className="col-md-4">
     <div className="form-group">
						<label>Student</label>
						<Select  options={
                               studentList.filter(item=>item.family_name===familyCode).map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.admissionNumber, studentName:list.studentName }
                             })} 
                        getOptionLabel={option =>option.label +' '+ option.studentName}

onChange={handleSelectStudent} className={errors.student ? 'form-control form-control-danger' : ''} name="student" value={result.student}  /> <span style={{color:'red'}}>{errors.student && errors.student}</span>
											</div>
                                            </section> 
				</div>	


	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={handleSearch} className="btn btn-inverse "><i className="fa fa-search"></i> Search Result</button>
								</footer>				
							</div>
                            </div>
							</div></div>
</div>

</div>
	

{scores.length!==0?
<div className="card z-depth-0">
		<div className="card-block">
	
        {schoolInfo&&schoolInfo.map((st, id)=>
                       <div className="row" key={id}>

<table className="table  table-full-width">
       <tbody>
                <tr>
                    <td className="pull-right" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${st.schoolLogo}`}  title="School Logo" alt=''  /></td>
                    <td className="text-center" >
                        
                        <img id="viewSchoolLogo2"  onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/headerlogo.png`}   alt={schoolName}  />
     </td>

 <td > <img id="viewSchoolLogo1" className="img-100" style={{ height:'100px' }} onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/'+student.gender+'.png'}} src={`${ImagesUrl}/students/${student.passport}`}  title={student.studentName} alt=''  /></td>
                </tr>
            </tbody>
       </table>

</div> 
                       )}

 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b> {result.examtype.label} RESULT</b></u></h5>
        </div></div><br/>

<div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>
                <tr>
                    <td colSpan={2}>NAME OF STUDENT: <b>{student.studentName}</b></td>
                    
                    
                 <td colSpan={2}>ADMISSION NO: <b> {student.admissionNumber} </b></td>
                    
                   
                </tr>
                <tr>
                <td>AGE: <b>{getAge(student.dateOfBirth)} </b></td>
                 <td colSpan={2}>CLASS: <b> {result.studentClass.label} </b></td>
                 
                 <td >GENDER: <b>{student.gender}</b></td>
                </tr>
                <tr>
                    
                    <td>TIME SCHOOL OPENED: <b> {Number(attendance.P)+Number(attendance.A)} </b></td>
                    <td>TIMES PRESENT: <b>{attendance.P} </b></td>
                    <td colSpan={2}>NEXT TERM BEGIN: <b>{longDate(atResult.nextTermBegin)} </b></td>
                </tr>
                
                   
                <tr>
                    
                <td >NUMBER IN CLASS: <b>{studentList.length}</b></td>
                    <td >TOTAL MARK OBTAINABLE: <b>{student.obtainable}</b></td>
                    <td >TOTAL MARK OBTAINED: <b>{student.obtained} </b></td>
                    
                    <td >AVERAGE: <b> {student.average}  </b></td>
                </tr>

                

            </tbody>
       </table></div>
       <div className="col-md-12 table-responsive">
        <table className="table table-striped table-bordered">
        

            <thead>
                <tr>
                    <th >Subjects</th>
                   
                    {getResultHead(result.studentClass.classtype)==='Secondary'? <th >Teacher</th>:''}
                    
                    <th >Total (100)</th>
                    <th >Grade</th>
                    <th >Remark</th>
                    <th >SUBJ AVG</th>
                </tr>
            </thead>
            <tbody>
                
                 {scores.length!==0?scores.map((st, idx)=> st.absent!=='Yes'?
                <tr key={idx}>
                
                    <td style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{st.subjectName.toUpperCase()}</td>
                    {getResultHead(result.studentClass.classtype)==='Secondary'?  <td style={{maxWidth:'120px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{handleStaffName(st.teacher)}</td>:''}
                    
                    <td>{st.total==='0'?'-':st.total}</td>
                    <td>{st.grade}</td>
                    <td>{st.remark}</td>
                    
                    <td>{FormatNumber(st.totalAverage)}</td>
                
            </tr>
                :''):[]} 
            </tbody> 
        </table>
 </div>	 
 <div className="col-md-12">
   
     <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'5px' }}><b>Form Tutor(s) </b></td>
                 <td style={{maxWidth:'95px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{handleStaffName(result.teacherName)}</td>

             </tr>
             <tr>
                 <td style={{maxWidth:'5px'}}><b>Comments </b></td>
                <td style={{maxWidth:'95px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{result.teacherRemark} </td>
                 
             </tr>
         </tbody>
     </table>

     {result.resultHead==='Nursery'?  <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'10px' }} ><b>Head of Nursery </b></td>
                 <td colSpan={2} style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{settings&& getStaffField(settings.headofNursery, 'staffName')}</td>
                 
             </tr>
             <tr>
                 <td style={{maxWidth:'5px'}}><b>Comments </b></td>
                <td style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>
                    
                
                <textarea className="form-control" placeholder='Type comment here' rows={3}  value={result.headRemark} name="headRemark"  onChange={handleChange} >
                    {result.headRemark}
                    </textarea>

                </td>
                <td style={{maxWidth:'5px' }}> <img id="viewPassport2" className="img-30"  src={`${ImagesUrl}/staff/signature/${getStaffField(settings&&settings.headofNursery, 'signature')}`}   alt={settings&& getStaffField(settings.headofNursery, 'staffName')} /></td>
                 
             </tr>
         </tbody>
     </table>: result.resultHead==='Primary'?
     <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'10px' }} ><b>Head of Primary </b></td>
                 <td colSpan={2} style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getStaffField(result.headName, 'staffName')}</td>
                 
             </tr>
             <tr>
                 <td style={{maxWidth:'5px'}}><b>Comments </b></td>
                <td style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{result.headRemark} </td>
                <td style={{maxWidth:'5px' }}><img id="viewPassport2" className="img-60"  src={`${ImagesUrl}/staff/signature/${getStaffField(result.headName, 'signature')}`}   alt={getStaffField(result.headName, 'staffName')} /></td>
                 
             </tr>
         </tbody>
     </table>:

     <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'10px' }} ><b>Head of Secondary </b></td>
                 <td colSpan={2} style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getStaffField(result.headName, 'staffName')}</td>
                 
             </tr>
             <tr>
                 <td style={{maxWidth:'5px'}}><b>Comments </b></td>
                <td style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{result.headRemark} </td>
                <td style={{maxWidth:'5px' }}><img id="viewPassport2" className="img-60"  src={`${ImagesUrl}/staff/signature/${getStaffField(result.headName, 'signature')}`}   alt={getStaffField(result.headName, 'staffName')} /></td>
                 
             </tr>
         </tbody>
     </table>}
     <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'10px' }}><b>Head of School </b></td>
                 <td style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{settings&& getStaffField(settings.headofSchool, 'staffName')}</td>
                 
                 <td style={{maxWidth:'5px' }}> 
                 <img id="viewPassport2" className="img-60"  src={`${ImagesUrl}/staff/signature/${getStaffField(settings&&settings.headofSchool, 'signature')}`}   alt={settings&& getStaffField(settings.headofSchool, 'staffName')} /></td>
             </tr>
         </tbody>
     </table>
 </div>
 </div> 
 
 <div className="row no-print">
        <div className="col-md-12 pull-right">
        <form method='POST' action={ServerUrl+'/printout/student_mock_result.php?token='+userToken} target='_blank'>

                <input type='hidden' name='onlyCategory' value ={JSON.stringify(onlyCategory, null, 2)} />
                <input type='hidden' name='scores' value ={JSON.stringify(scores, null, 2)} /> 
                <input type='hidden' name='studentAssessment' value ={JSON.stringify(studentAssessment, null, 2)} />
                <input type='hidden' name='totalStudent' value ={studentList.length} />
                <input type='hidden' name='grademark' value ={JSON.stringify(grademark, null, 2)} />
                <input type='hidden' name='result' value ={JSON.stringify(result, null, 2)} />
                <input type='hidden' name='student' value ={JSON.stringify(student, null, 2)} />

                <input type='hidden' name='classID' value ={result.studentClass.classID} />
                <input type='hidden' name='sectionID' value ={result.studentClass.value} />
                <input type='hidden' name='examCode' value ={result.examtype.value} />

                <input type='hidden' name='nextTermBegin' value ={longDate(atResult.nextTermBegin)} />
                <input type='hidden' name='attendance' value ={Number(attendance.P)+Number(attendance.A)} />
                <input type='hidden' name='present' value ={attendance.P} />
                 

                <input type='hidden' name='jwtToken' value ={Token} />
                <input type='hidden' name='imageUrl' value ={`${
ImagesUrl}/signature/${signature}`} />

        <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
        
        </form>
        </div></div>

 </div>
        </div>:''}










        
{studentAssessment.length!==0?
<div className="card z-depth-0">
		<div className="card-block">
	
        {schoolInfo&&schoolInfo.map((st, id)=>
                       <div className="row" key={id}>

<table className="table  table-full-width">
       <tbody>
                <tr>
                    <td className="pull-right" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${st.schoolLogo}`}  title="School Logo" alt=''  /></td>
                    <td className="text-center" >
                        
                        <img id="viewSchoolLogo2"  onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/headerlogo.png`}   alt={schoolName}  />
     </td>

 <td > <img id="viewSchoolLogo1" className="img-100" style={{ height:'100px' }} onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/'+student.gender+'.png'}} src={`${ImagesUrl}/students/${student.passport}`}  title={student.studentName} alt=''  /></td>
                </tr>
            </tbody>
       </table>

</div> 
                       )}

 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>  {result.examtype.label} STANDARDS REPORT</b></u></h5>
        </div></div><br/>

<div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>
                <tr>
                    <td colSpan='2'>NAME OF STUDENT: <b>{student.studentName}</b></td>
                    
                    <td>AGE: <b>{getAge(student.dateOfBirth)} </b></td>
                    <td>CLASS: <b> {result.studentClass.label} </b></td>
                </tr>
                <tr>
                    <td >NUMBER IN CLASS: <b>{studentList.length}</b></td>
                    <td colSpan={2}>ADMISSION NO: <b> {student.admissionNumber} </b></td>
                    
                    <td >GENDER: <b>{student.gender}</b></td>
                   
                </tr>
            </tbody>
       </table></div>
       <div className="col-md-12 table-responsive">
        <table className="table table-striped table-bordered ">
        
           <thead>
                <tr>
                    <th > AREA OF DEVELOPMENT</th>
                    <th >REMARKS</th>
                  
                </tr>
            </thead> 
           
            {onlyCategory&&onlyCategory.map((data, index)=>
            <tbody key={index}>
            <tr >
                              <th colSpan={2} >{data.type}</th>
                          </tr>
            {studentAssessment.map((list, id)=> list.type===data.type?
                          
                         <tr key={id}>
                                <td >{list.assessmentName}</td>
                                <td>{list.grade}</td>
                            </tr>:[])}
                            </tbody>
                            
                            )}
        </table>
 </div>	 
 <div className="col-md-12">

     
 <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'5px' }}><b>Form Tutor(s) </b></td>
                 <td style={{maxWidth:'95px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{handleStaffName(result.teacherName)}</td>

             </tr>
             <tr>
                 <td style={{maxWidth:'5px'}}><b>Comments </b></td>
                <td style={{maxWidth:'95px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{result.teacherRemark} </td>
                 
             </tr>
         </tbody>
     </table>

     {result.resultHead==='Nursery'?  <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'10px' }} ><b>Head of Nursery </b></td>
                 <td colSpan={2} style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{settings&& getStaffField(settings.headofNursery, 'staffName')}</td>
                 
             </tr>
             <tr>
                 <td style={{maxWidth:'5px'}}><b>Comments </b></td>
                <td style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>
                    
                
                <textarea className="form-control" placeholder='Type comment here' rows={3}  value={result.headRemark} name="headRemark"  onChange={handleChange} >
                    {result.headRemark}
                    </textarea>

                </td>
                <td style={{maxWidth:'5px' }}> <img id="viewPassport2" className="img-30"  src={`${ImagesUrl}/staff/signature/${getStaffField(settings&&settings.headofNursery, 'signature')}`}   alt={settings&& getStaffField(settings.headofNursery, 'staffName')} /></td>
                 
             </tr>
         </tbody>
     </table>: result.resultHead==='Primary'?
     <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'10px' }} ><b>Head of Primary </b></td>
                 <td colSpan={2} style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getStaffField(result.headName, 'staffName')}</td>
                 
             </tr>
             <tr>
                 <td style={{maxWidth:'5px'}}><b>Comments </b></td>
                <td style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{result.headRemark} </td>
                <td style={{maxWidth:'5px' }}><img id="viewPassport2" className="img-60"  src={`${ImagesUrl}/staff/signature/${getStaffField(result.headName, 'signature')}`}   alt={getStaffField(result.headName, 'staffName')} /></td>
                 
             </tr>
         </tbody>
     </table>:

     <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'10px' }} ><b>Head of Secondary </b></td>
                 <td colSpan={2} style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getStaffField(result.headName, 'staffName')}</td>
                 
             </tr>
             <tr>
                 <td style={{maxWidth:'5px'}}><b>Comments </b></td>
                <td style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{result.headRemark} </td>
                <td style={{maxWidth:'5px' }}><img id="viewPassport2" className="img-60"  src={`${ImagesUrl}/staff/signature/${getStaffField(result.headName, 'signature')}`}   alt={getStaffField(result.headName, 'staffName')} /></td>
                 
             </tr>
         </tbody>
     </table>}
     <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'10px' }}><b>Head of School </b></td>
                 <td style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getStaffField(result.headofSchool, 'staffName')}</td>
                 
                 <td style={{maxWidth:'5px' }}> <img id="viewPassport2" className="img-60"  src={`${ImagesUrl}/staff/signature/${getStaffField(result.headofSchool, 'signature')}`}   alt={getStaffField(result.headofSchool, 'staffName')} /></td>
             </tr>
         </tbody>
     </table>
 </div>
 </div> 

      
 <div className="row no-print">
        <div className="col-md-12 pull-right">
        <form method='POST' action={ServerUrl+'/printout/student_mock_result.php?token='+userToken} target='_blank'>
                <input type='hidden' name='student' value ={JSON.stringify(student, null, 2)} />
                <input type='hidden' name='scores' value ={JSON.stringify(scores, null, 2)} /> 
                <input type='hidden' name='studentAssessment' value ={JSON.stringify(studentAssessment, null, 2)} />
                <input type='hidden' name='totalStudent' value ={studentList.length} />
                <input type='hidden' name='grademark' value ={JSON.stringify(grademark, null, 2)} />
                <input type='hidden' name='result' value ={JSON.stringify(result, null, 2)} />
                <input type='hidden' name='onlyCategory' value ={JSON.stringify(onlyCategory, null, 2)} />
                <input type='hidden' name='classID' value ={result.studentClass.classID} />
                <input type='hidden' name='sectionID' value ={result.studentClass.value} />
                <input type='hidden' name='examCode' value ={result.examtype.value} />

                <input type='hidden' name='nextTermBegin' value ={longDate(atResult.nextTermBegin)} />
                <input type='hidden' name='attendance' value ={Number(attendance.P)+Number(attendance.A)} />
                <input type='hidden' name='present' value ={attendance.P} />
                 

                <input type='hidden' name='jwtToken' value ={Token} />
                <input type='hidden' name='imageUrl' value ={`${
ImagesUrl}/signature/${signature}`} />

        <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
        
        </form>
        </div></div>

 </div>
        </div>:''}


   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(ViewResult) 