import Cookies from 'js-cookie'
var CryptoJS = require('crypto-js')

export const FormatNumber = (num)=>{
    
    let newNumber = Number(num)
    return  newNumber.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const APP_KEY =process.env.REACT_APP_APP_KEY;
export  const ServerUrl = process.env.REACT_APP_SERVER_URL
export  const ImagesUrl = process.env.REACT_APP_IMAGES_URL
export  const studentUrl= process.env.REACT_APP_STUDENT_URL
export  const teacherUrl= process.env.REACT_APP_STAFF_URL
export  const ParentUrl= process.env.REACT_APP_PARENT_URL
export  const applicantUrl= process.env.REACT_APP_APPLICATION_URL
export  const LocalUrl = ParentUrl
export  const KAYUS_ULR = 'https://kayus.org'
export const DatabaseName= process.env.REACT_APP_DATABASE_NAME
export const domain = process.env.REACT_APP_DOMAIN
export  const mainUrl = process.env.REACT_APP_MAIN_URL
export const smsAccountUrl = process.env.REACT_APP_SMS_ACCOUNT_URL;




export const classID ='';
export const sectionID ='';

export const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

export const staffCode= '';
export const staffName ='';



export const familyCode= Cookies.get('pkauscd')?Cookies.get('pkauscd'):'null'
export const familyName = Cookies.get('pkstnm')?CryptoJS.AES.decrypt(Cookies.get('pkstnm'), APP_KEY).toString(CryptoJS.enc.Utf8):'null'
export const Token = Cookies.get('pkamtk')?Cookies.get('pkamtk'):'null'


export const config = {
    headers: { 'content-type': 'multipart/form-data'}
} 
export const Currency = '₦'




export  const PSKLIVE = 'pk_live_3d1e0b6826b44e63b467067c6036f41bc4edec1e'; 
export  const PSKTESTPublic = 'pk_test_69fe176f8520a2e60f1d40ec354e22f4c075163f';


export  const StudentUrl= process.env.REACT_APP_STUDENT_URL

export const schoolName = ''
//export  const LocalUrl = 'localhost';


