import React, {useState, useEffect} from 'react'
import {Alerts} from '../component/notify'
import { ServerUrl, ImagesUrl, config, KAYUS_ULR} from '../component/include'
import axios from 'axios'
import Cookies from 'js-cookie'

import {encrypt} from '../component/authentication'
import Preloader from '../component/preloader'


 const Login =()=> {
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [user, setUser] = useState({
		username:'',
		password:'',

		schoolInfo:[],
		remember:false,
		date:new Date().toISOString().slice(0,10),
		dateTime:new Date().toISOString().slice(0,19)
    })
    const [errors, setErrors] = useState({});

    const handleChange = event =>{		
        let {name, value} = event.target;	
        if(name==='remember'){
            value = !user.remember
        }	
       setUser({...user, [name] : value });
       setErrors({...errors, [name]:''});
    }
  
	
	const fetchSchoolInfo = async ()=>{
		
        const fd = new FormData();
		fd.append('schoolDetails', 'schoolDetails')
        let url = ServerUrl+'/fetch_details'
            const {data} = await axios.post(url, fd, config)
			setUser({...user, schoolInfo:data[0]})
    }


	function submit(event){  
		event.preventDefault();
		if(navigator.onLine){
		setNotice({...notice,  isLoading: true}) 	
           
        let formData = user;
        const fd = new FormData();
        for(let k in formData){
          fd.append(k, formData[k])
        }
           let url = ServerUrl+'/login_parent_controller.php?tablename=tbl_login_user';
		axios.post(url, fd, config)
		.then(response =>{
	if (response.data.type ==='success'){
		//1000 * 60 * SESSION_IDEL_MINUTES
		var inSixtyMinutes = Date.now() + 59 * 60 * 1000;
		var inOneMinutes = Date.now() + 58 * 60 * 1000;
		var inOneHours = new Date(new Date().getTime() + 59 * 60 * 1000);
		
		Cookies.set('_pexpt', inSixtyMinutes, {expires: inOneHours })
			Cookies.set('_pbexpt', inOneMinutes, {expires: inOneHours })

		Cookies.set('pkauscd', response.data.code, {expires: inOneHours })
		Cookies.set('pkamtk', response.data.jwt,  {expires: inOneHours })   
		Cookies.set('pkarfsh', response.data.userToken,  {expires: inOneHours })
		Cookies.set('pkstnm', encrypt(response.data.familyName),  {expires: inOneHours })


		Alerts('Welcome!', 'success', response.data.message)
		var queryString = new URLSearchParams(window.location.search).get("refferer")
		if(queryString!==null){
		  window.open(queryString, '_self')
		}else{
		  window.open('/exam/view_result', '_self')
		}


			}else if (response.data.type ==='error'){
				Alerts('Error!', 'danger', response.data.message)
			}    
		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
			 window.location.reload()
		}).finally(()=>{
            setIsSubmitting(false)
            setNotice({...notice,  isLoading: false }) 
        })    
		
	}else{
		Alerts('Error!', 'info', 'No internet connection')
	}
}


const clearCookies=()=>{
	
	Cookies.remove('pkauscd')	
 Cookies.remove('pkamtk')
 Cookies.remove('pkarfsh')
 Cookies.remove('pkstnm')



 Cookies.remove('_pexpt')
 Cookies.remove('_pbexpt')
}




useEffect(()=>{  
 clearCookies()
 fetchSchoolInfo()
},[]); 

        return (<>
			<Preloader />
  
			<div  style={{backgroundImage: `url(${ImagesUrl}/about/${user.schoolInfo.banner})`, backgroundSize:'cover', backgroundRepeat:'no-repeat'}}> 
			<section  className="login-block">
		  <div className="container">
			  <div className="row">
				  <div className="col-sm-12">
					  
	  <form className="md-float-material form-material"  role={'presentation'} autoComplete='off' onSubmit={submit}>
		  
	  <div className="auth-box card">
		  <div className="card-block">
			  <div className="row m-b-20">
				  <div className="col-md-12">
				  <div className="text-center">
		  <img src={ImagesUrl+'/about/'+user.schoolInfo.schoolLogo} className='img-100'  alt="logo.png" />
		  </div>
					  <h3 className="text-center">PARENT</h3>
				  </div>
			  </div>
			  <div className="form-group form-primary">
  
				  <input type="username"  required name="username" autoComplete='off' role={'presentation'} value={user.username} placeholder="Your Username" onChange={handleChange} className={errors.username  ? 'form-control formerror' : 'form-control'} /> 
  
			  </div>
			  <div className="form-group form-primary">
			  <input type="password" id="password" name="password" autoComplete='off' role={'presentation'}  value={user.password} placeholder="Password" required onChange={handleChange} className={errors.password  ? 'form-control formerror' : 'form-control'} /> 
			  </div>
			  <div className="row m-t-25 text-left">
				  <div className="col-12">
					  <div className="checkbox-fade fade-in-primary d-">
						  <label>
							  <input type="checkbox" checked={user.remember} id="remember" name="remember" onChange={handleChange} />
							  <span className="cr"><i className="cr-icon icofont icofont-ui-check txt-primary"></i></span>
							  <span className="text-inverse">Remember me</span>
						  </label>
					  </div>
					  <div className="forgot-phone text-right f-right">
						  <a href="#!" className="text-right f-w-600"> Forgot Password?</a>
					  </div>
				  </div>
			  </div>
			  <div className="row">
				  <div className="col-md-12">

				  {notice.isLoading ?	<button type="button" disabled className="btn btn-inverse btn-md btn-block  text-center m-b-20"><i className='fa fa-refresh fa-spin'></i> Please wait ... </button>:
				<button type="submit"  className="btn btn-inverse btn-md btn-block waves-effect waves-light text-center m-b-20">Sign in</button>}

				  </div>
			  </div>
			  <div className="row">
				<div className="col-md-10">
					<p className="text-inverse text-left m-b-0">Thank you.</p>
					<p className="text-inverse text-left"><a href={KAYUS_ULR} target={'_blank'}><b className="f-w-600">Powered By KAYUS</b></a></p>
				</div>
				<div className="col-md-2">
					
				<img src={ImagesUrl+'/about/kayus.png'} alt="KAYUS" className="img-30" />
				</div>
			</div>
		  </div>
							  </div>
						  </form>
				  </div>
			  </div>
		  </div>
		  </section>
	  </div>
  
  
  
		   
  </> 


 );
        
}

export default React.memo(Login) 